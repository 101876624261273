import React, { useEffect, useRef, useState } from 'react'
import classes from "./TaskBar.module.css";
import { Grid, Divider, Paper, Button, Tooltip, Collapse } from "@material-ui/core";
import { FaPlus, FaAmbulance, FaExclamationTriangle, FaExpandArrowsAlt, FaExpand, FaCopy, FaDollarSign, FaRegCopy, FaRegQuestionCircle, FaExpandAlt } from "react-icons/fa";
import { BsStopBtn, BsArrowsExpand } from "react-icons/bs";
import { BiCard, BiExpandAlt } from "react-icons/bi";
import { FiShare } from "react-icons/fi";
import { TbCurrencyDollarOff, TbCurrencyDollar } from "react-icons/tb";

import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { useBetween } from 'use-between';

import { convertDate } from '../Form/Form';

import toast from 'react-hot-toast';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    fontSize: '24px',
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function TaskBar({ store, home }) {
  const { graphData, stockInputValue, setEditMode, setStockInputValue, setSelectedDate, selectedDate, previousFormState, setPreviousFormState, numberOfStocks, setNumberOfStocks, setHasCustomizations, hasCustomizations, considerStockSplit, setConsiderStockSplit, overrideAmount, setOverrideAmount, overrideType, setOverrideTyp, setDisplayType, displayType, setShowAmounts, showAmounts } = useBetween(store);
  const [formats, setFormats] = React.useState(() => ['italic']);
  const [showHelp, setShowHelp] = React.useState(false);

  const handleAlignment = (event, newAlignment) => {
    window.scrollTo(0, 0);
    console.log(newAlignment)
    if (newAlignment && newAlignment !== displayType) {
      setDisplayType(newAlignment);
    }
  };

  const handleHelpShowing = () => {
    setShowHelp(!showHelp);
  };

  const handleAmountsShowing = () => {
    setShowAmounts(!showAmounts);
  };

  const resetToolbarView = () => {
    window.scrollTo(0, 0);
    setShowAmounts(true);
    setShowHelp(false);
    setDisplayType("FULL");
  }

  const constructLink = () => {
    console.log(stockInputValue)
    console.log(convertDate(selectedDate));
    console.log(numberOfStocks);
    let newLink = "";
    if (graphData && stockInputValue) {
      newLink = `${window.location.origin}/`;
      newLink += `?stock=${stockInputValue.symbol}`;
      if (selectedDate) {
        newLink += `&date=${convertDate(selectedDate)}`
      }
      if (numberOfStocks) {
        newLink += `&quantity=${numberOfStocks}`;
      }
      if (considerStockSplit) {
        newLink += `&customizations.adjustForStockSplits=${considerStockSplit}`;
      }
      if (overrideAmount && overrideType) {
        newLink += `&customizations.overrideInvestment=${overrideAmount}`;
        newLink += `&customizations.type=${overrideType}`;
      }
    }
    if (newLink === "" && window.location.href.includes("?")) {
      console.log("Link", window.location.href);
      newLink = window.location.href.replace("#howitworks", "");
    }

    if (newLink === "") {
      newLink = window.location.href.replace("#howitworks", "");
    }

    return newLink
  }

  const determineCurrentStock = () => {
    let currentSymbol = ""
    if (graphData && stockInputValue) {
      return stockInputValue.label
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("stock")) {
      return urlParams.get("stock");
    }
    return ""
  }

  const shareLink = async () => {
    const newLink = constructLink();
    // Feature that may not be supported
    let testShare = { someNewProperty: "Testing" };

    // Complex data that uses new key
    const shareData = {
      title: `If I Invested In ${determineCurrentStock()}`,
      text: "Visualize your investment",
      url: newLink,
    };

    console.log(shareData)

    // Test that the key is valid and supported before sharing
    if (window.location.protocol === "https:" && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        toast.error(`Can not share right now, copy link instead `, {
          className: classes.toasterColors,
        })
      }
    } else {
      // Handle case that new data property can't be shared.
      toast.error(`Your browser doesn't allow sharing`, {
        className: classes.toasterColors,
      })
    }
  }

  const copyLink = () => {
    const newLink = constructLink();

    navigator.clipboard.writeText(newLink).then(() => {
      toast.success('Copied to clipboard!', {
        className: classes.toasterColors,
      })
      /* Resolved - text copied to clipboard successfully */
    }, () => {
      toast.error('Failed to copy to clipboard!', {
        className: classes.toasterColors,
      })
      /* Rejected - text failed to copy to the clipboard */
    });
  }


  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.mainToolBarParent}
    >
      <Paper elevation={0} className={classes.paperGroup}>
      <StyledToggleButtonGroup
          size="large"
          value={displayType}
          exclusive
          onChange={handleAlignment}
          aria-label="Display Layout Customizations"
          className={classes.buttonGroupItems}
        >
            <ToggleButton value="FULL" aria-label="Full Screen" className={displayType === "FULL" ? classes.selectedToolbarButton: classes.toolbarButton}>
                <BsArrowsExpand className={classes.icon90Degress} />
            </ToggleButton>
          <ToggleButton value="BOX" aria-label="Box Screen" className={displayType === "BOX" ? classes.selectedToolbarButton : classes.toolbarButton}>
            <FaExpand />
          </ToggleButton>
          <ToggleButton value="CARD" aria-label="Small Card" className={displayType === "CARD" ? classes.selectedToolbarButton : classes.toolbarButton} label="Card">
            <BiCard />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.dividerItem} />
        <StyledToggleButtonGroup
          size="large"
          value={showAmounts}
          exclusive
          onChange={handleAmountsShowing}
          aria-label="Display Cost on Visualization"
          className={classes.buttonCostGroup}
        >
          <ToggleButton value={true} aria-label="Show cost on graph" className={showAmounts ? classes.selectedToolbarButton : classes.toolbarButton}>
            {showAmounts ? <TbCurrencyDollar /> :<TbCurrencyDollarOff />}
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.dividerItem} />
        <StyledToggleButtonGroup
          size="large"
          aria-label="Action items"
          className={classes.buttonActionGroup}
        >
          <ToggleButton onClick={() => shareLink()} aria-label="Share this live link">
            <FiShare />
          </ToggleButton>
          <ToggleButton onClick={() => copyLink()} aria-label="Copy Live Link to this Request">
            <FaRegCopy />
          </ToggleButton>
        </StyledToggleButtonGroup>
          <StyledToggleButtonGroup
            size="large"
            value={showHelp}
            exclusive
            onChange={handleHelpShowing}
            aria-label="Display help section"
            className={classes.buttonOtherGroup}
          >
            <ToggleButton value={true} aria-label="Show help section" className={showHelp ? classes.selectedToolbarButton : classes.toolbarButton}>
              <FaRegQuestionCircle />
            </ToggleButton>
          </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          size="large"
          aria-label="Reset all toolbar information in section"
          className={classes.buttonOtherGroup}
        >
          <Button onClick={() => resetToolbarView()} aria-label="Reset toolbar information" className={classes.resetButton}>
            RESET
          </Button>
        </StyledToggleButtonGroup>
        </Paper>
        <Collapse in={showHelp}>
          <div className={classes.helpMainParent}>
            <p>Here is a breakdown of what all the buttons do in the Toolbar above</p>
            <div className={classes.helpColumns}>
              {/* Display */}
              <div className={classes.helpColumnParent}>
                <div className={classes.helpColumn}>
                  <p className={classes.helpSubTitle}>Display Options</p>
                  <div className={classes.iconGroupItem}>
                    <span><BsArrowsExpand className={classes.icon90Degress} /></span>
                    <p><b>Full-Screen</b> visual of your investment.<br /><i>Try dark mode with full screen you won't be disappointed</i></p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <span><FaExpand/></span>
                    <p><b>Medium Display</b> of your investment. Great for laptops and tablets.</p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <span><BiCard /></span>
                    <p><b>Card Display</b> of your investment. Take a screenshot and post it on social media.</p>
                  </div>
                </div>
              </div>
              {/* middle section */}
              <div className={classes.helpColumnParent}>
                <div className={classes.helpColumn}>
                  <p className={classes.helpSubTitle}>Show/Hide Customizations</p>
                  <div className={classes.iconGroupItem}>
                    <span><TbCurrencyDollar /></span>
                    <p><b>Show the Cost</b> of your investments.<br />Default turned on to show your results and the dollar amounts of your investments.</p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <span><TbCurrencyDollarOff /></span>
                    <p><b>Hide the Cost</b> of your investments.<br /><i>Great for sharing screenshots with people without showing specifics!</i></p>
                  </div>
                </div>
              </div>
              {/* action section */}
              <div className={classes.helpColumnParent}>
                <div className={classes.helpColumn}>
                  <p className={classes.helpSubTitle}>Action Buttons</p>
                  <div className={classes.iconGroupItem}>
                    <span><FiShare /></span>
                    <p><b>Share</b> with others.<br /><i>Some browsers do not support this functionality.</i></p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <span><FaRegCopy /></span>
                    <p><b>Copy Live Link</b> to save for later.<br />Come back to this unique link to bypass filling out a New Request form, don't worry we will re-pull new data whenever you use it.</p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <span><FaRegQuestionCircle /></span>
                    <p><b>Show Help</b>... but I think you already know that.</p>
                  </div>
                  <div className={classes.iconGroupItem}>
                    <p className={classes.resetIcon}>RESET</p>
                    <p><b>Reset toolbar information</b> which will set your view to full screen and show the cost again.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
  </Grid>
  );
}