// utils/API.js
import axios from "axios";

let environment = "http://localhost:4000";
if (process.env.REACT_APP_ENV === "DEV") {
  environment = "http://investingapp-env.eba-xzic5kxp.us-east-1.elasticbeanstalk.com:4000/"
}
if (process.env.REACT_APP_ENV === "PROD") {
  environment = "https://api.ifiinvested.in/"
}

if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - API environment", environment)}

export default axios.create({
  baseURL: environment,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  }
});