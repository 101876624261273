import React, { useEffect, useState } from "react";
import { AppBar, Container, Grid, Typography, Button, makeStyles } from "@material-ui/core";
import theme from "../../theme";
import { FaTwitter } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
    root: {
        //   display: 'flex',
        //   flexDirection: 'column',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   padding: theme.spacing(2),

        // '& .MuiTextField-root': {
        //   margin: theme.spacing(1),
        //   width: '300px',
        // },
        // '& .MuiButtonBase-root': {
        //   margin: theme.spacing(2),
        // },

    },
    logoImage: {
        maxHeight: '60px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '45px',
        },
    },
    footerItemsGrid: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '600px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '50px',
        }
    }
}));

const Footer = ({ }) => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear()
    return (
        <AppBar position="static" style={{ background: "#000000", borderTop: "1px solid #484848" }}>
            <Container maxWidth>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            sm={12}
                            style={{ padding: theme.props.spacing.sm }}>
                            <Grid>
                                <a href="http://www.juiceboxmonkeydesigns.com" target="_blank">
                                    <img src="./assets/JBMD_monkey_logo_dark_mode.png" className={classes.logoImage} />
                                </a>
                            </Grid>
                            <Grid style={{paddingLeft: theme.props.spacing.sm, paddingBottom: theme.props.spacing.sm}}>
                                <Typography variant="caption" style={{ fontWeight: 400, color: "#8E8E8E" }}>
                                    Owned and Operated by
                                </Typography>
                                <Typography variant="body1" style={{ fontWeight: 300, color: "#8E8E8E" }}>
                                    JUICE BOX MONKEY
                                </Typography>
                                <Typography variant="body1" style={{ fontWeight: 600, color: "#8E8E8E", lineHeight: '0.8' }}>
                                    DESIGNS
                                </Typography>
                            </Grid>
                        </Grid>
                        </Grid>
                        <Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.footerItemsGrid}>
                            <Grid item xs={12} sm={4} md={3} justify="center" alignItems="center" style={{ textAlign: "center" }}>
                                <Button href="https://twitter.com/ifiinvested_in" target="_blank" color="inherit">
                                    <FaTwitter />&nbsp;ifiinvested_in
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} justify="center" alignItems="center" style={{ textAlign: "center" }}>
                                <Button href="http://www.juiceboxmonkeydesigns.com/privacy-policy" target="_blank" color="inherit">
                                    Privacy Policy
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}  style={{ margin: "auto ", textAlign: "center" }}>
                                <Button href="http://www.juiceboxmonkeydesigns.com/if_i_invested_in" target="_blank"  color="inherit">
                                    Support Page
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
                                <Typography variant="button" style={{ color: "#8E8E8E"}}>
                                COPYRIGHT © {currentYear}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    )
}

export default Footer;