import React, { useEffect, useRef, useState  } from 'react'
import classes from "./GraphHeader.module.css";
import { Grid } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import { useBetween } from 'use-between';
import numeral from 'numeral';
// import { FaAsterisk } from 'react-icons/fa';
import { RiArrowUpDownLine } from 'react-icons/ri'; 

function getStockData(graphData) {
    if (graphData && graphData.symbol && graphData.name && graphData.amount) {
        return {
            symbol: graphData.symbol,
            name: graphData.name,
            numberOfStocks: graphData.amount,
            count: graphData.results[graphData.results.length -1].count || 0,
            hasSplit: graphData.results[graphData.results.length -1].hasSplit || false,
        }
    } else {
        return {
            symbol: "AAPL",
            name: "Apple Inc.",
            numberOfStocks: 5,
        }
    }
}

function getStockName(currentData, considerStockSplit, displayTypeCard) {
    const stockNumber = currentData.hasSplit ? currentData.count : currentData.numberOfStocks;

    return (
        <div>
            <p className={`${displayTypeCard ? classes.mainAmountLabelCard: classes.mainAmountLabel}`}>
            {currentData.hasSplit &&
                <Tooltip
                    title={
                        <p className={classes.overrideTooltip}>
                        Adjusted for Stock Split< br/><br />
                                {`Your original investment of ${numeral(currentData.numberOfStocks).format("0,0")} stock${currentData.numberOfStocks > 1 ? 's': ''} in ${currentData.name} has been split.`}
                        <br /><br />
                        {`As of today your stock investment is now worth ${numeral(stockNumber).format("0,0")} stock${stockNumber > 1 ? 's.' : '.'}`}
                        </p>
                    }
                    arrow
                    classes={{
                        tooltip: classes.tooltipBackground,
                        arrow: classes.tooltipArrow
                      }}
                >
                    <IconButton aria-label="info">
                        <RiArrowUpDownLine className={classes.splitIcon}/>
                    </IconButton>
                </Tooltip>
            }
            <span className={`${classes.mainAmountValueLabel}`}>
                {numeral(stockNumber).format("0,0")}
            </span>
            stock{stockNumber > 1 && `s`} of
        </p>
        </div>
    )
}

const GraphHeader = ({store }) => {
    const { graphLoading, graphData, considerStockSplit, showAmounts, displayType } = useBetween(store);
    const [currentData, setCurrentData] = useState(getStockData(graphData))
    useEffect(() => {
        if (graphData !== null) {
            setCurrentData(getStockData(graphData))
        }
    }, [graphData])
    return (
        <div>
        {displayType === "CARD" ?
            <div>
                {showAmounts && getStockName(currentData, considerStockSplit, true)}
                <h1 className={`${classes.mainValueLabelCard}`}>{currentData.symbol}</h1>
                <p className={classes.displayValueLabelLongCard}>{currentData.name}</p>
            </div>
        :
            <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            >

                {showAmounts && getStockName(currentData, considerStockSplit, false)}
                <h1 className={`${classes.mainValueLabel}`}>{currentData.symbol}</h1>
                <p className={classes.displayValueLabelLong}>{currentData.name}</p>
            </Grid>
        }
        </div>
    )
}
export default GraphHeader;