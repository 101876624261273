import React from "react";

import DisplayGraph from "./views/DisplayGraph/DisplayGraph"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import DateFnsUtils from '@date-io/date-fns';
import theme from './theme';
import { Toaster } from 'react-hot-toast';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      name: null,
      avatar: null,
      email: null
    };
  }

  render() {
    const { isLoading, name, avatar, email } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DisplayGraph />
          <Toaster
            position="bottom-center" />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }

}

export default App;

