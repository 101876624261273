
import React, { useEffect, useRef, useState } from 'react'
import classes from "./LineGraph.module.css";
import annotationPlugin from 'chartjs-plugin-annotation';
import * as StockSample from '../../data/aapl_short.json';
import Chart from 'chart.js/auto';
import { useBetween } from 'use-between';
import moment from 'moment';
import numeral from 'numeral';
Chart.register(annotationPlugin);

function determineColourSetForDark(same) { // is the colour dark in dark mode
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return same ? "#000000" : "#FFFFFF";
    }
    return same ? "#FFFFFF" : "#000000";
}

function formatDateForGraph(date) {
    // TODO: Look into this further
    // return moment(date).format('ll');)
    return moment(date.split('T')[0]).format('ll');
}

const LineGraph = ({ store }) => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const { graphLoading, graphData, showAmounts } = useBetween(store);
    const [currentGraphData, setCurrentGraphData] = useState(StockSample.default);
    const [mode, setMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);

    useEffect(() => {
        // Add listener to update styles
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'));
    }, []);

    useEffect(() => {
        if (graphData !== null && chartInstance) {
            chartInstance.data.datasets[0].data = graphData.results.map((x => x.amount));
            const data = graphData.results.map((x => x.amount))
            chartInstance.data.datasets[0].borderColor = data.map((value) => value < 0 ? '#FD4757' : '#65DA78');
            chartInstance.data.datasets[0].backgroundColor = data.map((value) => value < 0 ? '#FD4757' : '#65DA78');
            chartInstance.data.labels = graphData.results.map((x => formatDateForGraph(x.date)));
            // Reset for dark mode
            chartInstance.options.plugins.tooltip.backgroundColor = determineColourSetForDark(true);
            chartInstance.options.plugins.tooltip.titleColor = determineColourSetForDark(false);
            chartInstance.options.plugins.tooltip.footerColor = determineColourSetForDark(false);
            chartInstance.options.plugins.tooltip.label = showAmounts;

            chartInstance.update();
        };
    })

    useEffect(() => {
        if (chartInstance) {
            chartInstance.options.plugins.tooltip.enabled = showAmounts;
            chartInstance.update();
        }
    }, [showAmounts])

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "PROD") {
            console.log("TEST - GRAPH LOADING, current graph data", currentGraphData);
            console.log("TEST - Local graph data in state (not from store)", graphData);
        };
        if (chartContainer && chartContainer.current && currentGraphData) {
            if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - RERENDER?", currentGraphData)}
            const data = currentGraphData.results.map((x) => x.amount);
            const tranformedData = data.map((x) => numeral(x).format("$0,0.00"))
            const colours = data.map((value) => value < 0 ? '#FD4757' : '#65DA78');

            const newChartInstance = new Chart(chartContainer.current, {
                type: "bar",
                data: {
                    //Bring in data
                    labels: currentGraphData.results.map((x => formatDateForGraph(x.date))),
                    datasets: [
                        {
                            data,
                            borderColor: colours,
                            backgroundColor: colours,
                            borderWidth: 0,
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            barPercentage: 0.90,
                            categoryPercentage: 1,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    plugins: {
                        tooltip: {
                            enabled: showAmounts,
                            backgroundColor: determineColourSetForDark(true),
                            borderColor: '#DEDEDE',
                            borderWidth: '0.5',
                            titleColor: determineColourSetForDark(false),
                            titleAlign: 'right',
                            displayColors: true,
                            cornerRadius: 0,
                            bodyAlign: 'right',
                            bodyFont: {
                                size: 24,
                                family: 'fresno',
                            },
                            footerColor: determineColourSetForDark(false),
                            footerAlign: 'right',
                            footerFont: {
                                size: 14,
                                // family: 'fresno',
                            },
                            boxWidth: 0,
                            boxHeight: 0,
                            usePointStyle: true,
                            padding: {
                                top: 16,
                                bottom: 8,
                                right: 8,
                                left: 8,
                            },
                            callbacks: {
                                labelTextColor: function (context) {
                                    if (chartInstance) {
                                        return chartInstance.data.datasets[0].borderColor
                                    }
                                },
                                labelPointStyle: function (context) {
                                    return {
                                        pointStyle: 'star',
                                        rotation: 0
                                    }
                                },
                                title: function (tooltipItem, data) {
                                    return
                                },
                                label: function (tooltipItem, data) {
                                    return numeral(tooltipItem.raw).format("$0,00.00");
                                },
                                // afterLabel: function(tooltipItem, data) {
                                //     return numeral(tooltipItem.raw).format("$0,00.00");
                                // },
                                footer: function (tooltipItem, data) {
                                    return tooltipItem[0].label
                                }
                            }

                        },
                        legend: {
                            position: 'none',
                        },
                        title: {
                            display: false,
                            text: 'Investment Chart'
                        },
                        autocolors: false,
                        // TICKS ON THE SIDE OF THE GRAPH
                        // annotation: {
                        //     annotations: {
                        //         line1: {
                        //             type: 'line',
                        //             yMin: 0,
                        //             yMax: 0,
                        //             borderColor: '#DEDEDE',
                        //             borderWidth: 2,
                        //         }
                        //     }
                        // }

                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                // label: false
                                drawBorder: false,
                            },
                            ticks: {
                                display: false //this will remove only the label
                            },
                            //   gridLines: {
                            //     zeroLineWidth: 3,
                            //     zeroLineColor: "#2C292E",
                            //   }
                            //   display: false
                        },
                        y: {
                            grid: {
                                zeroLineWidth: 10,
                                zeroLineColor: determineColourSetForDark(false),
                                //   display: false,
                                drawOnChartArea: false,
                                drawBorder: false,
                            },
                            // display: false
                            ticks: {
                                display: false //this will remove only the label
                            },
                        }
                    },
                    //Customize chart options
                },
            });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer, currentGraphData])
    return (
        <div className={classes.graphContainer}>
            <canvas
                id="stockChart"
                ref={chartContainer}
                style={{ maxHeight: "500px" }}
            />
        </div>
    )
}

export default LineGraph;
