import { useState } from 'react';

export const useShareableState = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [graphInitialLoading, setGraphInitialLoading] = useState(true);
    const [initialButtonClick, setInitialButtonClick] = useState(true);
    const [initialError, setInitialError] = useState(false);
    const [graphLoading, setGraphLoading] = useState(false);
    const [graphData, setGraphData] = useState(null);

    // edit mode
    const [editMode, setEditMode] = useState(false);
    const [stockInputValue, setStockInputValue] = useState(null);
    const [numberOfStocks, setNumberOfStocks] = useState();
    const [selectedDate, setSelectedDate] = useState(null);
    // customizations
    const [hasCustomizations, setHasCustomizations] = useState(false); // used to open or close the customization section
    const [considerStockSplit, setConsiderStockSplit] = useState(false);
    const [overrideAmount, setOverrideAmount] = useState("");
    const [overrideType, setOverrideType] = useState("");

    // Recent Tags
    const [recentSymbolsLoading, setRecentSymbolsLoading] = useState(true);
    const [recentSymbols, setRecentSymbols] = useState([]);

    // For resetting form state back if cancelled request
    const [previousFormState, setPreviousFormState] = useState(null);

    // For display
    const [displayType, setDisplayType] = useState("FULL");
    const [showAmounts, setShowAmounts] = useState(true);

    const [count, setCount] = useState(0);
    return {
      initialLoading,
      setInitialLoading,
      graphInitialLoading,
      setGraphInitialLoading,
      initialButtonClick,
      setInitialButtonClick,
      initialError,
      setInitialError,
      graphLoading,
      setGraphLoading,
      graphData,
      setGraphData,
      editMode,
      setEditMode,
      stockInputValue,
      setStockInputValue,
      numberOfStocks,
      setNumberOfStocks,
      selectedDate,
      setSelectedDate,
      hasCustomizations,
      setHasCustomizations,
      considerStockSplit,
      setConsiderStockSplit,
      overrideAmount,
      setOverrideAmount,
      overrideType,
      setOverrideType,
      previousFormState,
      setPreviousFormState,
      recentSymbolsLoading,
      setRecentSymbolsLoading,
      recentSymbols,
      setRecentSymbols,
      displayType,
      setDisplayType,
      showAmounts,
      setShowAmounts,
      count,
      setCount
    };
  };