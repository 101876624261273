import API from "../utils/API";
import * as StockSample from '../data/aapl_long.json';
import * as RecentSample from '../data/recent.json';

/**
 * For getting view display on default
 */
export function getDefault() {
    // return API.get('')
}

/**
 * Getting stock information
 */

export async function getStock(symbol) {
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - search stock with symbol", symbol) };
    try {
        const responseSearch = await API.get(`api/stock?symbol=${symbol}`);
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - Search by symbol", responseSearch) };
        if (responseSearch.status === 201 && responseSearch.data) {
            return {error: false, message: "Success", data: responseSearch.data }
        } else {
            return { error: true, message: "Doesn't exist"}
        }
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - ERROR in getting stock with symbol", err) };
        // let message = "A limit has been reached."
        let message = "Any issue has occured.";
        if (err?.response?.data?.error) {
            message = err.response.data.error;
        }
        return {error: true, message };
    }
}

/**
 * Getting stock search output
 * */
export async function searchStocks(search) {
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - search params", search) };
    try {
        const responseSearch = await API.get("api/stock/search", {
            params: {
                keyword: search
            }
        });
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - initial response from searching symbol", responseSearch) };
        if (responseSearch.status === 201) {
            return {error: false, message: "Success", data: responseSearch.data }
        } else {
            return { error: true, message: responseSearch.error }
        }
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - ERROR in search symbol", err) };
        // let message = "A limit has been reached."
        let message = "Any issue has occured.";
        if (err?.response?.data?.error) {
            message = err.response.data.error;
        }
        return {error: true, message };
    }
}

export async function formSubmit(submitObject) {
    if (process.env.REACT_APP_ENV !== "PROD") { 
        console.log("TEST - Pull authMarker Before", window.localStorage.authMarker);
        console.log("TEST - Submit object:", submitObject);
    }
    const authMarker = window.localStorage.authMarker || null;
    try {
        const responseSearch = await API.post("api/user/request", {
            ...submitObject,
            authMarker,
        });
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - initial response from formsubmission", responseSearch) };
        if (responseSearch.status === 200 && responseSearch.data.status === 200) {
          return {error: false, message: "Success", data: responseSearch.data.results }
        } else {
            return { error: true, message: responseSearch.data.err }
        }
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - ERROR in formsubmission", err) };
        return {error: true, message: err}
    }
}

export async function initialLoad(customLiveLink) {    
    try {
        if (process.env.REACT_APP_ENV !== "PROD") { 
            console.log("TEST - Pull authMarker Before", window.localStorage.authMarker);
        }
        const customLiveLinkView = (customLiveLink && Object.keys(customLiveLink).length > 0)
        const responseObject = customLiveLinkView ? await API.post(`api/user/initial?authMarker=${window.localStorage.authMarker}`, {
            ...customLiveLink
        }) : await API.get(`api/user/initial?authMarker=${window.localStorage.authMarker}`);
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST-initial load response", responseObject);}
        
        if (!customLiveLinkView) {
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
        if (responseObject.status === 200 && responseObject.data.status === 200) {
          if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST-initial load response success status", responseObject);}
          if (responseObject.data.results.authMarker !== 'undefined') {
            window.localStorage.setItem("authMarker", responseObject.data.results.authMarker);
          }
          return {error: false, message: "Success", data: responseObject.data.results }
        } else {
            console.log("HITTING HERE - inside API")
          return { error: true, message: responseObject.data.err }
        }
    } catch (err) {
        console.log("err", err);
        return { error: false, message: "Success", data: StockSample.default } 
    }
}

export async function recentLoad() {    
    try {
        if (process.env.REACT_APP_ENV !== "PROD") { 
            console.log("TEST - Pull authMarker Before", window.localStorage.authMarker);
        }
        const responseObject = await API.get(`api/user/recent?authMarker=${window.localStorage.authMarker}`);
        if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST- recent load response", responseObject);}
        await new Promise(resolve => setTimeout(resolve, 5000));
        if (responseObject.status === 200 && responseObject.data.status === 200) {
          if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST- recent load response success status", responseObject);}
          return {error: false, message: "Success", data: responseObject.data.results }
        } else {
            console.log("HITTING HERE - inside API")
          return { error: true, message: responseObject.data.err }
        }
    } catch (err) {
        console.log("err", err);
        return { error: false, message: "Success", data: RecentSample.recents } 
    }
}

export function recentSubmits() {

}