import React, { useEffect, useState } from 'react';
import { useBetween } from "use-between";
import { Typography, TextField, Button, Grid, CircularProgress, Chip, Collapse, FormControlLabel, Checkbox, Tooltip, InputAdornment, ButtonGroup } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { searchStocks, formSubmit, getStock } from "../../data/api";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import theme from "../../theme";
import { FaChartBar, FaRegQuestionCircle } from "react-icons/fa";
import classes from "./Form.module.css";

export function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

export function convertDisplayDate(inputFormat) {
  // 2001-01-01 ==> DD/MM/YYYY
  return inputFormat;
  const splitDate = inputFormat.split('-');
  return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
}

const Form = ({ handleCancel, handleSuccessSubmit, width, store }) => {
  const { graphLoading, setGraphLoading, setGraphData, setStockInputValue, stockInputValue, editMode, numberOfStocks, setNumberOfStocks, selectedDate, setSelectedDate, recentSymbols, considerStockSplit, setConsiderStockSplit, overrideAmount, setOverrideAmount, overrideType, setOverrideType, setHasCustomizations, hasCustomizations } = useBetween(store);
  // create state variables for each input
  const [options, setOptions] = React.useState([]);
  const [earliestDateForSelectedStock, setEarliestDateForSelectedStock] = React.useState(null);
  const [selectBeforeEarliestDateWarning, setSelectBeforeEarliestDateWarning] = React.useState(false);
  const [selectedEarlyDate, setSelectedEarlyDate] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isCustomizationOpen, setIsCustomizationOpen] = React.useState(hasCustomizations);

  useEffect(() => {
  }, [store, stockInputValue]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (graphLoading) {
      return;
    }
    setGraphLoading(true);
    // verify stock amount
    if (!numberOfStocks || (numberOfStocks && numberOfStocks === "0")) {
      setErrorMessage(`Not a valid number inside "Number of Stocks" to purchase.`);
      setGraphLoading(false);
      setError(true);
      return;
    }
    if (!stockInputValue) {
      setErrorMessage(`Valid stock was not selected, please research stock symbol and select stock.`);
      setGraphLoading(false);
      setError(true);
      return;
    }

    if (!selectedDate) {
      setErrorMessage(`Date is not valid, please select another date.`);
      setGraphLoading(false);
      setError(true);
      return;
    }

    // Customization additions
    // // example: {
    //     "overrideInvestment":  1000,
    //     "type": "FULL",
    //     "adjustForStockSplits": true
    // }
    const customizations = {};
    if (considerStockSplit) {
      customizations.adjustForStockSplits = true;
    }

    console.log(overrideAmount)
    if (overrideAmount && (overrideAmount !== "" || overrideAmount !=="0")) {
      // type - all numbers or periods
      const reg = new RegExp('[^.0-9]+');
      const resultOnType = reg.exec(overrideAmount);
      if (resultOnType) {
        setGraphLoading(false);
        setErrorMessage(`Override amount value ${overrideAmount} is not formatted correctly. Please review and try submitting again.`);
        setError(true);
        return;
      } else {
        customizations.overrideInvestment = overrideAmount;
      }
      // verify type
      if (!['FULL', 'STOCK'].includes(overrideType)) {
        setGraphLoading(false);
        setErrorMessage(`Override type is required when using Override Purchase Amount Customization. Please select either "By Stock" or "Total" related to your purchase amount.`);
        setError(true);
        return;
      } else {
        customizations.type = overrideType;
      }
    }

    if (process.env.REACT_APP_ENV !== "PROD") {
      console.log("TEST - Customizaion during submission", customizations);
    }

    if (customizations !== {}) {
      setHasCustomizations(true)
    }

    setError(false);

    let formSubmitData = {
      startDate: convertDate(selectedDate),
      symbol: stockInputValue.symbol,
      amount: numberOfStocks
    };
    if (customizations !== {}) {
      formSubmitData.customizations = customizations;
    }

    if (process.env.REACT_APP_ENV !== "PROD") { 
      console.log("TEST - graphing loading metric", graphLoading);
      console.log("TEST - convert date call on select date", convertDate(selectedDate));
      console.log("TEST - collected of data", numberOfStocks, stockInputValue, selectedDate);
      console.log("TEST - offical send response", formSubmitData)
    }


    const graphData = await formSubmit(formSubmitData);
    // handle error response
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - Graph data response", graphData); }
    if (graphData.error) {
      setGraphLoading(false);
      setErrorMessage(graphData.message);
      setError(true);
    } else {
      // SUCCESS
      setGraphData(graphData.data);
      setGraphLoading(false);
      handleSuccessSubmit();
    }
  };

  const handleSelectStockSymbol = async (symbol) => {
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - selected symbol", symbol); }
    const stockData = await getStock(symbol);
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - Response from getting stock data", stockData); }
    if (!stockData.error && stockData.data?.earliestRecord) {
      const slicedDate = stockData.data?.earliestRecord.split("T")[0]
      setEarliestDateForSelectedStock(slicedDate)
    }
  }

  const handleChange = async event => {
    event.persist()
    if (event.target.value.length >= 2) {
      const searchCall = await searchStocks(event.target.value);
      if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - search call value", searchCall); }
      if (searchCall.error) {
        setErrorMessage(searchCall.message);
        setError(true);
        return
      }
      setError(false);
      setOptions(searchCall.data);
    }
  };

  const handleDateChange = (date) => {
    const furtherBack = new Date()
    furtherBack.setDate(furtherBack.getDate() - theme.props.environmentVariables.numberOfDaysTillMonth);
    if (date < furtherBack) {
      setSelectedEarlyDate(true);
    } else {
      setSelectedEarlyDate(false);
    }

    /**
     * Condition for stock info
     */
    if (earliestDateForSelectedStock) {
      const oldestDate = new Date(earliestDateForSelectedStock);
      if (date < oldestDate) {
        setSelectBeforeEarliestDateWarning(true);
      } else {
        setSelectBeforeEarliestDateWarning(false);
      }
    }

    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - handle date change", date); }
    setSelectedDate(date);
  };

  const disableWeekends = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const constructRecentTags = () => {
    return (<div>
      {recentSymbols.map((item, i) => {
        return(<Chip label={item.symbol} component="a" onClick={() => {
          console.log("Before", stockInputValue);
          console.log(item)
          setStockInputValue(item);
          handleSelectStockSymbol(item.symbol);
          console.log(stockInputValue);
        }} className={classes.chipComponent} />)
      })}
    </div>)
  }

  const handleCustomization = () => {
    isCustomizationOpen ? setIsCustomizationOpen(false) : setIsCustomizationOpen(true)
  };

  return (
    <form style={{ maxWidth: `${width}` }} className={classes.root} onSubmit={handleSubmit}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.formParentGrid}
      >
        <Grid>
          <Typography
            variant="h4"
            gutterBottom
            align="left"
            className={classes.formTitle}
            >
            {editMode ? "Edit" : "New"} Request
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body" gutterBottom align="left"
          className={classes.formDescription}
          style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.sm
          }}>
            Fill out form below to visually see how much your investment is worth through the time of investment.
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            className={classes.formSubTitle}>
            I bought
          </Typography>
        </Grid>
        <TextField
          label="Number of Stocks"
          variant="filled"
          required
          type="number"
          className={classes.formFill}
          InputProps={{
            inputProps: {
              max: 10000, min: 0
            },
          }}
          value={numberOfStocks}
          onChange={e => setNumberOfStocks(e.target.value)}
          fullWidth
          style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.sm
          }}
        />
        <Grid>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            className={classes.formSubTitle}
          >
            stock(s) of
          </Typography>
        </Grid>
        {/* For Edit */}
        {stockInputValue && editMode &&
        <Autocomplete
          id="stock"
          fullWidth
          disabled
          value={`${stockInputValue["symbol"]} - ${stockInputValue["name"]}`}
          onChange={(event, item) => {
            if (item && item.symbol) {
              handleSelectStockSymbol(item.symbol);
            } else {
              // resetting
              setSelectBeforeEarliestDateWarning(false);
              setEarliestDateForSelectedStock(null);
              setError(false);
            }
          }}
          getOptionLabel={(option) => {
            // setStockInputValue(option)
            return typeof option === "string" ? option : `${option.symbol} - ${option.name}`
          }}
          filterOptions={x => {
            return x;
          }}
          options={[stockInputValue]}
          autoComplete
          includeInputInList
          freeSolo
          disableOpenOnFocus
          renderInput={params => (
            <TextField
              {...params }
              label="Search Stock Symbol"
              variant="filled"
              required
              value="le"
              className={classes.formFill}
              onChange={handleChange}
            />
          )}
          renderOption={option => {
            return (
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {option.symbol}
                  </Typography>
                </Grid>
                <Grid container justify="space-between" direction="row">
                  <Grid item>
                    {option.name}
                  </Grid>
                  {/* TODO: ADD IN LATER */}
                  <Grid item>
                    {option.region}
                  </Grid>
                  {/* <Grid item xs>
                    {option.currency}
                </Grid> */}
                </Grid>
              </Grid>
            );
          }}
          style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.sm
          }}
        />
        }
        {!editMode &&
        <Autocomplete
          id="stock"
          fullWidth
          onChange={(event, item) => {
            if (item && item.symbol) {
              setStockInputValue(item)
              handleSelectStockSymbol(item.symbol);
            } else {
              // resetting
              setSelectBeforeEarliestDateWarning(false);
              setEarliestDateForSelectedStock(null);
              setError(false);
            }
          }}
          value={stockInputValue ? `${stockInputValue["symbol"]} - ${stockInputValue["name"]}`: ""}
          filterOptions={x => {
            return x;
          }}
          options={options}
          autoComplete
          includeInputInList
          freeSolo
          disableOpenOnFocus
          renderInput={params => (
            <TextField
              {...params }
              label="Search Stock Symbol"
              variant="filled"
              required
              className={classes.formFill}
              onChange={handleChange}
            />
          )}
          renderOption={option => {
            return (
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {option.symbol}
                  </Typography>
                </Grid>
                <Grid container justify="space-between" direction="row">
                  <Grid item>
                    {option.name}
                  </Grid>
                  {/* TODO: ADD IN LATER */}
                  <Grid item>
                    {option.region}
                  </Grid>
                  {/* <Grid item xs>
                    {option.currency}
                </Grid> */}
                </Grid>
              </Grid>
            );
          }}
          style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.sm
          }}
        />
        }
        {!editMode && (recentSymbols.length !== 0) &&
        <Grid>
          <Typography
            variant="p"
            gutterBottom
            align="left"
            className={classes.formRecentTitle}
          >
            Recent Symbols
          </Typography>
          {constructRecentTags()}
        </Grid>
        }
        <Grid>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            className={classes.formSubTitle}
          >
            on
          </Typography>
        </Grid>
        <KeyboardDatePicker
          minDate="1980-01-01"
          disableFuture
          shouldDisableDate={disableWeekends}
          variant="inline"
          value={selectedDate}
          inputVariant="filled"
          label="Date of Purchase"
          format="yyyy/MM/dd"
          // format="dd/MM/yyyy"
          openTo="year"
          views={["year", "month", "date"]}
          required
          hintText="Weekends Disabled"
          onChange={newDate => {
            handleDateChange(newDate);
          }}
          KeyboardButtonProps={{
            onFocus: e => {
              setIsOpen(true);
            }
          }}
          PopoverProps={{
            disableRestoreFocus: true,
            onClose: () => {
              setIsOpen(false);
            },
            onSubmit: () => {
              setIsOpen(false);
            }
          }}
          InputProps={{
            onFocus: () => {
              setIsOpen(true);
            }
          }}
          open={isOpen}
          className={classes.formFill}
          style={{
            width: "100%",
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.md
          }}
        />
        <div style={{
          width: "100%",
          marginTop: theme.props.spacing.sm,
          marginBottom: theme.props.spacing.xxl
        }}>
          <div className={classes.customizationParentTopSection}>
            <Typography
              variant="h6"
              gutterBottom
              align="left"
              className={classes.customizationSubTitle}
            >
              Customizations
            </Typography>
            <Button
            className={classes.customizationRevealButton}
            onClick={handleCustomization}>
              {isCustomizationOpen ? "Hide" : "Show"}
            </Button>
          </div>
          <Collapse in={isCustomizationOpen}>
            <div style={{justifyContent: "flex-start", alignItems: "center", display: "flex", paddingBottom: "8px"}}>
            <FormControlLabel
              value="end"
              control={<Checkbox className={classes.checkboxIcon}/>}
              label={<Typography variant="body1" className={classes.checkboxLabel}>Adjust for Stock Split</Typography>}
              labelPlacement="end"
              inputProps={{ 'aria-label': 'Adjust for stock split checkbox' }}
              checked={considerStockSplit}
              onChange={(element) => {
                console.log(element.target.checked)
                setConsiderStockSplit(element.target.checked)
              }}
            />
            <Tooltip title={
              <p className={classes.overrideTooltip}>
                Adjust for Stock Split< br/><br />
              This will consider any stock split(s) since your original investment day and adjust your amount of stocks based on splits.<br /><br />Ex. If you bought 5 stocks of AAPL in 2019, there was a 4 for 1 stock split in August 2020, meaning today your investment would be 20 stocks.<br /><br />Multiple Splits? No problem, we will calculate it all for you.
              </p>
              }
              classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow
              }}
              aria-label="add"
              placement="top"
              arrow>
              <span className={classes.tooltipIconText}><FaRegQuestionCircle /></span>
            </Tooltip>
            </div>
            {considerStockSplit && 
              <Alert
              severity="info"
              fullWidth
              className={classes.alertInfo}
              >
                This may increase loading your results, but please do not reload the page. We need to focus while calculating your results.
              </Alert>
            }
            <div style={{justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              className={classes.overridePurchaseTitle}
            >
              Override Purchase Amount
            </Typography>
            <Tooltip title={
              <p className={classes.overrideTooltip}>
                Override Purchase Amount< br/><br />
              Know the exact amount you spent on the investment day?<br /><br />Fill in the purchase amount below and select if the purchase amount is by each stock or the total investment amount.
              </p>
              }
              classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow
              }}
              aria-label="add"
              placement="top"
              arrow>
              <span className={classes.tooltipIconText}><FaRegQuestionCircle /></span>
            </Tooltip>
            </div>
            <TextField
              label="Purchase Amount"
              id="standard-start-adornment"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="filled"
              className={classes.formFill}
              value={overrideAmount}
              onChange={e => setOverrideAmount(e.target.value)}
            />
            <ButtonGroup size="small" aria-label="small outlined button group" fullWidth>
              <Button
                onClick={() => setOverrideType("STOCK")}
                className={overrideType === "STOCK" ? classes.overridePurchaseButtonActive : classes.overridePurchaseButton}
                >By Stock</Button>
              <Button
                onClick={() => setOverrideType("FULL")}
                className={overrideType === "FULL" ? classes.overridePurchaseButtonActive : classes.overridePurchaseButton}
                >Total</Button>
            </ButtonGroup>

          </Collapse>
        </div>
        {selectBeforeEarliestDateWarning &&
          <Grid fullWidth style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.xl
          }}>
            <Alert
              severity="warning"
              fullWidth
              className={classes.alertInfo}
            >
              We only have data back to {convertDisplayDate(earliestDateForSelectedStock)} for this stock, you can only see results for this stock after this date.
            </Alert>
          </Grid>
        }
        {/* <KeyboardDatePicker
        onClick={() => setPickerStatus}
        minDate="1980-01-01"
        disableFuture
        hintText="Weekends Disabled"
        shouldDisableDate={disableWeekends}
        openTo="year"
        format="dd/MM/yyyy"
        label="Purchase Date"
        views={["year", "month", "date"]}
        value={selectedDate}
        onChange={handleDateChange}
        fullWidth
        rightArrowIcon
      /> */}
        {((selectedEarlyDate && !earliestDateForSelectedStock) || (selectedEarlyDate && !selectBeforeEarliestDateWarning)) &&
          <Grid fullWidth style={{
            marginTop: theme.props.spacing.sm,
            marginBottom: theme.props.spacing.xl
          }}>
            <Alert
              severity="info"
              fullWidth
              className={classes.alertInfo}
            >
              We only show breakdown by day within 365 days, anything larger will breakdown by month and use the month end amount to calculate your estimated investment amount.
            </Alert>
          </Grid>
        }
        {/* <Alert severity="info" fullWidth>
      <AlertTitle>Info</AlertTitle>
      This is an info alert — <strong>check it out!</strong>
    </Alert> */}
        <div className={classes.fullDisplayButtons}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={selectBeforeEarliestDateWarning}
            className={classes.submitButton}
            fullWidth
            startIcon={graphLoading ? <div style={{ maxHeight: '30px' }}><CircularProgress style={{ width: '20px', height: '20px' }} color={'inherit'} /></div> : <FaChartBar />}
          >
            {graphLoading ?
              "Loading Results"
              :
              "Display Results"
            }
          </Button>
          <Button
            fullWidth
            className={classes.cancelButton}
            onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        {hasError &&
          <Grid fullWidth style={{
            marginTop: theme.props.spacing.lg,
            marginBottom: theme.props.spacing.xl
          }}>
            <Alert
              severity="error"
              fullWidth
              className={classes.errorInfo}
            >
              {errorMessage}
            </Alert>
          </Grid>
        }
      </Grid>
    </form>
  );
};

export default Form;
