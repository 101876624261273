import React, { useEffect, useRef, useState  } from 'react'
import classes from "./CardTooltip.module.css";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useBetween } from 'use-between';
import numeral from 'numeral';
import { IoWarning } from 'react-icons/io5';

function determineTypeColour(type) {
    switch (type) {
        case 'initial':
        case 'even':
            return classes.mainValueInitial;
        case 'lose':
            return classes.mainValueLose;
        case 'gain':
            return classes.mainValueGain;
        default:
            return classes.mainValueInitial;
    }
}

function determinAlginment(type, displayType) {
    if (type === 'initial') {
        return classes.parentCardTooltipLeft;
    }
    if (displayType === "CARD") {
        return classes.parentCardTooltipRightCard
    }
    return classes.parentCardTooltipRight;
}

function constructMainValue(type, graphData) {
    if (graphData) {
        if (type === 'initial') {
            return numeral(graphData.startResult.amount).format("$0,0.00");
        } else {
            return numeral(graphData.results[graphData.results.length - 1].amount).format("$0,0.00")
        }
    }
    return numeral(1200).format("$0,0.00");
}

function constructMainDate(type, graphData) {
    if (graphData) {
        if (type === 'initial') {
            return graphData.startResult.startDate.split('T')[0];
        } else {
            // 2021-06-21T04:00:00.000Z
            return graphData.results[graphData.results.length -1].date.split('T')[0];
        }
    }
    return "2021-01-01";
}

function constructStockSplitNumberOfStocks(type, graphData) {
    let stockNumber = 0;
    if (graphData) {
        if (type === 'initial') {
            stockNumber = graphData.amount;
        } else {
            // When stock split is considered but there is no stock split during a specific timeframe, it comes back as count:1
            stockNumber = graphData.results[graphData.results.length -1]?.hasSplit ? graphData.results[graphData.results.length -1].count: graphData.amount;
        }
    }
    if (stockNumber !== 0) {
        return stockNumber > 1 ? `${numeral(stockNumber).format("0,0")} Stocks` : `${stockNumber} Stock`
    }
    return null;
}

function determineShowTradingWarning(type, graphData) {
    if (graphData && type !== 'initial') {
        // condition within last 2 months (like in backend condition)
        if ( moment().subtract(2, "months") > moment(graphData.results[graphData.results.length -1].date)) return true
    }
    return false
}

function constructTopValue(type) {
    switch (type) {
        case 'initial':
            return "Initial Investment";
        case 'even':
            return "Break Even";
        case 'lose':
            return "Loss";
        case 'gain':
            return "Gain";
        default:
            return "Other";
    }
}

function shouldShow(type, graphData) {
    if (graphData) {
        switch (type) {
            case 'initial':
                return true;
            case 'even':
                if (graphData.results[graphData.results.length - 1] === 0) return true
            case 'gain':
                if (graphData.results[graphData.results.length - 1] > 0) return true
            case 'lose':
                if (graphData.results[graphData.results.length - 1] < 0) return true
            default:
                return false;
        }
    }
    return false;
}

const CardTooltip = ({store, type}) => {
    const { graphLoading, graphData, considerStockSplit, showAmounts, displayType } = useBetween(store);
    const display = determineTypeColour(type);
    const alignment = determinAlginment(type, displayType);
    const [hasSplit, setHasSplit] = useState(false)

    useEffect(() => {
        if (graphData !== null) {
            setHasSplit(graphData.results[graphData.results.length - 1].hasSplit)
        }
    }, [graphData]);
    const currentDisplay = shouldShow(type, graphData);
    // if (!currentDisplay) { return (); }
    return (
        <div className={`${alignment} ${classes.parentCardTooltip}`}>
            <p className={displayType === "CARD"? classes.topLabelCardTooltipCard : classes.topLabelCardTooltip}>{constructTopValue(type)}</p>
            {showAmounts && (considerStockSplit || hasSplit) && 
                 <p className={classes.dateValueCardTooltip}>{constructStockSplitNumberOfStocks(type, graphData)}</p>
            }
            <h1 className={`${display} ${displayType === "CARD" ? classes.mainValueCardTooltipCard : classes.mainValueCardTooltip}`}> {showAmounts && constructMainValue(type, graphData)}</h1>
            <div className={classes.dateCardLine}>
                {determineShowTradingWarning(type, graphData) &&
                <Tooltip title={<p className={classes.noLongerTradingTooltipP}>
                    This stock has stopped trading, this is the last result we can display for this stock.
                </p>} arrow classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow
              }}><IconButton aria-label="info"><IoWarning className={classes.noLongerTradingWarningIcon} /></IconButton></Tooltip>
                }
                <p className={displayType === "CARD" ? classes.dateValueCardTooltipCard : classes.dateValueCardTooltip}>{constructMainDate(type, graphData)}</p>
            </div>
        </div>
    )
}
export default CardTooltip;