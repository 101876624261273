import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: { 500: '#707070' },
  },
  // typography: {
  //   fontFamily: "hel"
  // },
  props: {
    spacing: {
      xxs: "2px",
      xs: "4px",
      sm: "8px",
      md: "16px",
      lg: "24px",
      xl: "32px",
      xxl: "48px",
    },
    environmentVariables: {
      numberOfDaysTillMonth: 365,
    }
  }
})
export default theme