import React, { useEffect, useRef, useState  } from 'react'
import classes from "./LoadingGraphWindow.module.css";
import { Grid, Hidden } from "@material-ui/core";
import { FaPlus, FaAmbulance, FaExclamationTriangle } from "react-icons/fa";
import { useBetween } from 'use-between';

const LoadingGraphWindow = ({store,  home}) =>  {
    const { graphInitialLoading, initialButtonClick, initialError} = useBetween(store);

    return (
        <Grid
        container
        direction = "column"
        justify = "center"
        alignItems="center"
        className={home ? classes.loadingOuterContainer : classes.loadingOuterContainerLiveLink}
        >
            {home &&
                <Grid
                direction = "column"
                justify = "center"
                alignItems="center"
                className={classes.loadingChildContainer}>
                    <h1 className={classes.questionLabel}>If you invested in 5 stocks of Apple (AAPL) in April 2010, what would it be worth today?</h1>
                    { initialError &&
                        <h2 className={classes.brokenError}>
                            <span className={classes.brokenErrorIcon}><FaAmbulance /></span><br />
                            There has been an error trying to collect your data. 
                        </h2>
                    }
                    { graphInitialLoading && 
                        <h2 className={classes.loadingAnimation}>
                            <img className={classes.logoIcon} src="./assets/logo-icon.png" alt="If I Invested . in offical logo" /><br />
                        Loading the answer
                        </h2>
                    }
                    <Hidden only={['xs']}>
                        <h2 className={classes.createOwn}> Or create your own by clicking <span className={classes.buttonFake}> <FaPlus className={classes.plusIconInline} /> NEW REQUEST</span> above.</h2>
                    </Hidden>
                    <Hidden smUp>
                        <h2 className={classes.createOwn}> Or create your own by clicking <span className={classes.mobileButtonFake}> <FaPlus className={classes.mobilePlusIconInline} /></span> above.</h2>
                    </Hidden>
                </Grid>
            }

            {!home &&
                <Grid
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.loadingChildContainer}>
                    {initialError &&
                    <div>
                        <h2 className={classes.brokenError}>
                            <span className={classes.brokenErrorIcon}><FaExclamationTriangle /></span><br />
                            There has been an error trying to collect the data <br />
                        <br />
                        </h2>
                        <Hidden only={['xs']}>
                            <h2 className={classes.createOwn}> Support has been information, in the meantime, <br /> please create a new link by clicking <span className={classes.buttonFake}> <FaPlus className={classes.plusIconInline} /> NEW REQUEST</span> above.</h2>
                        </Hidden>
                        <Hidden smUp>
                            <h2 className={classes.createOwn}> Support has been information, in the meantime, <br /> please create a new link by clicking <span className={classes.mobileButtonFake}> <FaPlus className={classes.mobilePlusIconInline} /></span> above.</h2>
                        </Hidden>
                    </div>
                    }
                    {graphInitialLoading &&
                        <h2 className={classes.loadingAnimation}>
                            <img className={classes.logoIcon} src="./assets/logo-icon.png" alt="If I Invested . in offical logo" /><br />
                            Updating with recent data
                        </h2>
                    }
                </Grid>
            }

        </Grid>
    )
}

export default LoadingGraphWindow;