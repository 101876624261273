import React, { useEffect, useState } from 'react';
import classes from "./Recent.module.css";
import { Paper, Grid, Chip } from "@material-ui/core";
import * as Recent from "../../data/recent.json";

// https://medium.com/applike/https-medium-com-applike-react-responsive-conditional-rendering-of-component-c97ab247097d

const breakToMobile = 950;

const NestedGrid = ({}) => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [recentData, setRecentData] = useState(Recent.recents);
  const [adData, setAdData] = useState(Recent.ads);
  const [arrayRecent, setArrayRecent] = useState(constructArrayRecent());
  function updatePredicate() {
    const currentView = window.innerWidth > breakToMobile;
    setIsDesktop(currentView)
  };
  window.addEventListener("resize", updatePredicate);

  useEffect(() => {
    if (window.innerWidth > breakToMobile) { setIsDesktop(true)}
    if (window.innerWidth < breakToMobile) {  setIsDesktop(false)}
  }, [isDesktop])

  function StockCard(symbol, choice) {
    let link = `/?stock=${symbol}&date=${choice.date}&quantity=${choice.amount}`;
    if (choice.tag === "stockSplit") {
      link += "&customizations.adjustForStockSplits=true";
    }
    return(
      <a href={link} name="Click to visualize data" className={classes.buttonRecentCard}>
        <div>
          <p className={classes.stockCardMiddleHeader}><span className={classes.stockCardBoldHeader}>{choice.amount}</span> stock{choice.amount > 1 && `s`} of</p>
          <h1 className={`${classes.stockCardHeader} ${(choice.gain ? classes.mainValueGain : classes.mainValueLose)}`}>{symbol}</h1>
          <p className={classes.stockCardMiddleHeader}>on &nbsp;<span className={classes.stockCardBoldHeader}>{choice.date}</span></p>
          <div>
            {choice.tag === "stockSplit" &&
              <Chip label="Adjusted for Stock Split" className={classes.chipComponent} variant="outlined" size="small" />
            }
            {!choice.tag &&
              <Chip label="Basic Request" className={classes.chipComponent} variant="outlined" size="small" />
            }
          </div>
        </div>
      </a>
    )
  }

  function AdCard(link, assetLink) {
    return(
      <a href={link} target="_blank">
        <img src={assetLink} className={classes.logoImage}/>
      </a>
    )
  }

  function constructArrayRecent() {
    // I I [] I
    // I [] I I
    var stockItems = [];
    var arr = [];
    while(arr.length < 6){
      var r = Math.floor(Math.random() * recentData.length -1) + 1;
      if(arr.indexOf(r) === -1) arr.push(r);
    }
    arr.forEach((r) => {
      var symbol = recentData[r].symbol;
      var choice = Math.floor(Math.random() * recentData[r].choices.length -1 ) + 1
      stockItems.push({symbol, choice: recentData[r].choices[choice]})
    });
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - stock item  before ads", stockItems); }
    // ads

    var arrAds = [];
    while(arrAds.length < 2) {
      var e = Math.floor(Math.random() * adData.length -1) + 1;
      if(arrAds.indexOf(e) === -1) arrAds.push(e);
    }
    // insert together
    stockItems.splice(2, 0, adData[arrAds[0]]);
    stockItems.splice(5, 0, adData[arrAds[1]]);
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - stock items after ads inserts", stockItems); }
    return stockItems
  }


  function renderPaper(currentItem) {
    if (!currentItem) { return }
    if (process.env.REACT_APP_ENV !== "PROD") { console.log("TEST - render paper results", currentItem);}
    if (currentItem.hasOwnProperty('link')) {
      return AdCard(currentItem.link, currentItem.assetLink)
    } else {
      return StockCard(currentItem.symbol, currentItem.choice)
    }
  }

  function FormRow(itemStart, numberLoop) {
    return (
      <React.Fragment>
        {[...Array(numberLoop).keys()].map((value) => (
          <Grid item>
            <Paper className={classes.paper}>
              {renderPaper(arrayRecent[itemStart + value])}
            </Paper>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={1} margin={8} className={classes.root}>
      <h1 className={`${classes.mainValueLabel}`}>Recent Requests</h1>
      <h2 className={`${classes.secondaryLabel}`}>All requests are live links, click to visualize data for yourself</h2>
      <Grid item xs={12}>
        {isDesktop ?
        <React.Fragment>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(0, 4)}
          </Grid>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(4, 4)}
          </Grid>
        </React.Fragment>
        :
        <React.Fragment>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(0, 2)}
          </Grid>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(2, 2)}
          </Grid>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(4, 2)}
          </Grid>
          <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
            {FormRow(6, 2)}
          </Grid>
        </React.Fragment>
        }
      </Grid>
    </Grid>
  );
}

export default NestedGrid;