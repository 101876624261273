import React, {useEffect, useState} from "react";
import LineGraph from "../../components/LineGraph/LineGraph";
import classes from "./DisplayGraph.module.css";
// import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Container, Grid, Box } from "@material-ui/core";
import { useShareableState } from "../../utils/store";
import theme from "../../theme";
import CardTooltip from "../../components/CardTooltip/CardTooltip";
import GraphHeader from "../../components/GraphHeader/GraphHeader";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import Recent from "../../components/Recent/Recent";
import TaskBar from "../../components/TaskBar/TaskBar";
import Donation from "../../components/Donation/Donation";
import { useBetween } from 'use-between';
import LoadingGraphWindow from "../../components/LoadingGraphWindow/LoadingGraphWindow";
import { initialLoad, recentLoad } from "../../data/api";

const store = useShareableState;

function shouldShow(type, graphData) {
    if (graphData) {
        switch (type) {
            case 'initial':
                return true;
            case 'even':
                if (graphData.results[graphData.results.length - 1].amount === 0) return true
                return false
            case 'gain':
                if (graphData.results[graphData.results.length - 1].amount > 0) return true
                return false
            case 'lose':
                if (graphData.results[graphData.results.length - 1].amount < 0) return true
                return false
            default:
                return false;
        }
    }
    return false;
}

function App() {
const {
    graphInitialLoading,
    setGraphInitialLoading,
    graphLoading,
    graphData,
    setGraphData,
    initialError,
    setInitialError,
    initialLoading,
    setInitialLoading,
    recentSymbolsLoading,
    setRecentSymbolsLoading,
    recentSymbols,
    setRecentSymbols,
    displayType
} = useBetween(store);
const [graphDirection, setGraphDirection] = useState("gain");

const handleInitialLoad = async (custom) => {
    const initial = await initialLoad(custom);
    if (initial.error) {
        setInitialError(true);
        setGraphInitialLoading(false);
    } else {
        // success 
        setGraphData(initial.data);
        setInitialLoading(false);
        setGraphInitialLoading(false);
    }
}

const handleRecentLoad = async () => {
    const initial = await recentLoad();
    setRecentSymbols(initial.data)
    setRecentSymbolsLoading(false);
    console.log("BLUE", initial)
}

const constructCustomLiveLink = (queryString) => {
    const urlParams = new URLSearchParams(queryString);
    // has all items
    const stock = urlParams.get('stock');
    const date = urlParams.get('date');
    const quantity = urlParams.get('quantity');

    if (!stock && !date && !quantity) {
        return {}
    }

    // has customize
    const customizations = {};
    let hasCustomizations = false;
    if (queryString.includes("customizations")) {
        if (urlParams.has("customizations.adjustForStockSplits")) {
            customizations["adjustForStockSplits"] = urlParams.get("customizations.adjustForStockSplits")
            hasCustomizations = true;
        }
        if (urlParams.has("customizations.overrideInvestment") && urlParams.has("customizations.type")) {
            customizations["overrideInvestment"] = urlParams.get("customizations.overrideInvestment")
            customizations["type"] = urlParams.get("customizations.type") === "FULL" ? "FULL" : "STOCK";
            hasCustomizations = true;
        }
    }

    const customLinkData = {
        symbol: stock,
        startDate: date,
        amount: quantity,
    }
    if (hasCustomizations) {
        customLinkData["customizations"] = customizations
    }

    return customLinkData
}


useEffect(() => {
    if (initialLoading && !graphData && !graphLoading && !initialError) {
        /**
         * Custom Live Live support
         * MUST have `stock`, `date` and `quantity`
         * with options of `customize` items
         */
        const queryString = window.location.search;
        console.log("queryString", queryString);
        const customLiveLink = constructCustomLiveLink(queryString);
        handleInitialLoad(customLiveLink);
    }
    if (recentSymbolsLoading && recentSymbols.length === 0) {
        handleRecentLoad();
    }
    if(shouldShow('gain', graphData)) {setGraphDirection("gain")}
    if(shouldShow('lose', graphData)) {setGraphDirection("lose")}
    if(shouldShow('even', graphData)) {setGraphDirection("even")}
}, [graphData, graphInitialLoading, initialError, initialLoading, graphLoading, recentSymbolsLoading, recentSymbols]);

return (
    <div>
    <Container maxWidth style={{marginBottom: theme.props.spacing.xxl}}>
        <Header store={store} home={window.location.search === ""}/>
        {window.location.search === "" && displayType !== "CARD" &&
            <GraphHeader store={store} />
        }
        { initialLoading ?
            <LoadingGraphWindow store={store} home={window.location.search === ""} />
        :
            <div className={displayType === "FULL" ? classes.customSectionFullWidth : displayType === "BOX" ? classes.customSectionBoxWidth : classes.customSectionCardWidth}>
            {window.location.search !== "" && displayType !== "CARD" &&
                <GraphHeader store={store} />
            }
            {displayType === "CARD" &&
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"flex-end"}
                    // style={{ marginBottom: theme.props.spacing.md }}
                >
                    <GraphHeader store={store} />
                    {graphDirection === "gain" && <CardTooltip store={store} type="gain" />}
                </Grid>
            }
            <div className={`${classes.graphSectionContainer}`}>
                { (graphDirection === "gain" && displayType !== "CARD") &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems={"flex-end"}
                    style={{marginBottom: theme.props.spacing.md}}>
                        {graphDirection === "gain" && <CardTooltip store={store} type="gain" />}
                </Grid>
                }
                <LineGraph store={store}/>
                <Box display="flex" style={{marginTop: theme.props.spacing.md}}>
                    <Box>
                        <CardTooltip store={store} type="initial" />
                    </Box>
                    <Box flexGrow={1}></Box>
                    <Box>
                        { graphDirection === "lose" &&
                            <CardTooltip store={store} type="lose" />
                        }
                        { graphDirection === "even" &&
                            <CardTooltip store={store} type="even" />
                        }
                    </Box>
                </Box>
            </div>
        </div>
        }
        <TaskBar store={store} />
        {/* <Banner /> */}
        <HowItWorks />
        <Recent store={store} />
    </Container>
    <Donation />
    <Footer />
    </div>
);
}

export default App;

