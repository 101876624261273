import React, { useEffect, useState } from 'react';
import classes from "./Donation.module.css";
import { FaPlus, FaChartBar } from "react-icons/fa";
import { AiOutlineNumber } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiCalendarEvent, BiCoffeeTogo } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoBeerOutline } from "react-icons/io5";
import { GiHamburger } from "react-icons/gi"
import { Paper, Grid, Button } from '@material-ui/core';

export default function Donation() {
    return (
        <Grid container className={classes.root}>
            <Grid container xs={12}>
                <React.Fragment>
                    <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
                        <Grid item xs={12} sm={6} md={7}>
                            <h1 className={`${classes.mainValueLabel}`}>LIKE WHAT YOU SEE?</h1>
                            <p className={`${classes.mainParagraphLabel}`}>Help us continue our mission to deliver visually and simple ways to read and view your investment data.</p>
                            <p className={`${classes.mainDonateLabel}`}>Donate a</p>
                            <div>
                                <a href="https://link.juiceboxmonkeydesigns.com/5F9hI2" target="_blank">
                                    <button className={`${classes.btn} ${classes.success}`}>
                                        <div className={`${classes.btnChild}`}>
                                            <BiCoffeeTogo /><span className={`${classes.btnText}`}>Coffee</span><MdKeyboardArrowRight />
                                        </div>
                                    </button>
                                </a>
                                <a href="https://link.juiceboxmonkeydesigns.com/9KoRBf" target="_blank">
                                    <button className={`${classes.btn} ${classes.success}`}>
                                        <div className={`${classes.btnChild}`}>
                                            <IoBeerOutline /><span className={`${classes.btnText}`}>Beer</span><MdKeyboardArrowRight />
                                        </div>
                                    </button>
                                </a>
                                <a href="https://link.juiceboxmonkeydesigns.com/upV2ml" target="_blank">
                                    <button className={`${classes.btn} ${classes.success}`}>
                                        <div className={`${classes.btnChild}`}>
                                            <GiHamburger /><span className={`${classes.btnText}`}>Burger</span><MdKeyboardArrowRight />
                                        </div>
                                    </button>
                                </a>
                            </div>
                            <p className={`${classes.mainCODisclaimer}`}>1% of all donations will be contributed to removing CO<sub>2</sub> from the atmosphere.</p>
                            <p className={`${classes.mainDisclaimer}`}>Address will be collected during checkout and used only to send stickers for the donation. Free stickers promotion only applies to Canada and USA.</p>
                            <p className={`${classes.mainDisclaimer}`}>Juice Box Monkey Designs owns and operates <span className={`${classes.logoText}`}>IF I INVESTED</span> <span className={`${classes.logoDotText}`}>.</span> <span className={`${classes.logoText}`}>IN</span></p>
                            
                        </Grid>

                        {/* Form section */}
                        <Grid item xs={12} sm={6} md={5}>
                            <div className={`${classes.stickerParent}`}>
                                <img src="./assets/invested_in_stickers.png" alt= "Visualize your investment logo of If I Invested In" className={classes.stickerImage} />
                                <p className={`${classes.freeStickers}`}>Free stickers with every donation*</p>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Grid>
        </Grid>
    )
}