import React, { useEffect, useState } from 'react';
import classes from "./HowItWorks.module.css";
import { FaPlus, FaChartBar } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { AiOutlineNumber } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiCalendarEvent } from "react-icons/bi";
import { Paper, Grid } from '@material-ui/core';

export default function HowItWorks() {
    return (
        <Grid container spacing={1} className={classes.root}>
            <h1 id="howitworks" className={`${classes.mainValueLabel}`}>HOW IT WORKS</h1>
            <Grid container xs={12}>
                <React.Fragment>
                    <Grid container spacing={3} className={`${classes.gridMainGroup}`}>
                        {/* Click Start */}
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.numberCircle}`}>1</div>
                            </div>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSection}`}>Click</p>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <a onClick={() => window.scrollTo(0, 0)}>
                                        <span className={classes.buttonFake}> <HiPlus className={classes.plusIconInline} /> NEW REQUEST</span>
                                    </a>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSectionSmall}`}>(at the top)</p>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSection}`}>to start</p>
                                </div>
                            </div>
                        </Grid>

                        {/* Form section */}
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.numberCircle}`}>2</div>
                            </div>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSection}`}>Fill out the form with</p>
                                </div>
                                <div>
                                    <div className={`${classes.formSectionParent}`}>
                                        <div className={`${classes.textSectionParent}`}>
                                            <span className={`${classes.text2Form}`}> <AiOutlineNumber className={classes.iconInline} /> of Stocks</span>
                                        </div>
                                    </div>
                                    <div className={`${classes.formSectionParent}`}>
                                        <div className={`${classes.textSectionParent}`}>
                                            <span className={`${classes.text2Form}`}> <BsSearch className={classes.iconInline} /> Stock Symbol</span>
                                        </div>
                                    </div>
                                    <div className={`${classes.formSectionParent}`}>
                                        <div className={`${classes.textSectionParent}`}>
                                            <span className={`${classes.text2Form}`}> <BiCalendarEvent className={classes.iconInline} /> Date of Purchase</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSectionSmall}`}>Plus customizations like stock splitting.</p>
                                </div>
                            </div>
                        </Grid>
                        {/* And visualize */}
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.numberCircle}`}>3</div>
                            </div>
                            <div className={`${classes.numberCircleParent}`}>
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSection}`}>Then click</p>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <span className={classes.buttonFake}> <FaChartBar className={classes.plusIconInline} /> DISPLAY RESULTS</span>
                                </div>
                                <br />
                                <div className={`${classes.textSectionParent}`}>
                                    <p className={`${classes.textSection}`}>and visualize</p>
                                </div>
                                <div className={`${classes.textSectionParent}`}>
                                    <img src="./assets/logo-icon.png" alt= "Visualize your investment logo of If I Invested In" className={classes.visualizeIcon} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Grid>
        </Grid>
    )
}