import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    MenuIcon,
    Typography,
    Box,
    Button,
    Drawer,
    MuiThemeProvider,
    Grid,
    makeStyles,
    Hidden
} from "@material-ui/core";
import { useBetween } from "use-between";
import classes from "./Header.module.css";
import Form from "../Form/Form";
import { FaPlus, FaEdit, FaRegQuestionCircle} from "react-icons/fa";
import { HiPlus, HiPencilAlt } from "react-icons/hi"

const useStyles = makeStyles(theme => ({
    root: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   padding: theme.spacing(2),
  
      // '& .MuiTextField-root': {
      //   margin: theme.spacing(1),
      //   width: '300px',
      // },
      // '& .MuiButtonBase-root': {
      //   margin: theme.spacing(2),
      // },

    },
    betaLabel: {
        fontFamily: "fresno",
        letterSpacing: '2.2px',
        color: "#65DA78"
    },
    logoImage: {
        maxHeight: '70px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '45px',
          },
    },
    headerButton: {
        height: 40,
        [theme.breakpoints.down('sm')]: {
            height: 35,
          },
    }
  }));

const Header = ({store, home}) => {
    const { graphData, stockInputValue, setEditMode, setStockInputValue, setSelectedDate, selectedDate, previousFormState, setPreviousFormState, numberOfStocks, setNumberOfStocks, setHasCustomizations, hasCustomizations, considerStockSplit, setConsiderStockSplit, overrideAmount, setOverrideAmount, overrideType, setOverrideType} = useBetween(store);
    // const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleCancelDrawer = () => {
        // Reset values back
        if (previousFormState) {
            setEditMode(true)
            setStockInputValue(previousFormState.stockInputValue);
            setNumberOfStocks(previousFormState.numberOfStocks);
            setSelectedDate(previousFormState.selectedDate);
            setHasCustomizations(previousFormState.hasCustomizations);
            setConsiderStockSplit(previousFormState.considerStockSplit);
            setOverrideAmount(previousFormState.overrideAmount);
            setOverrideType(previousFormState.overrideType);
        } else {
            setStockInputValue(null);
        }
        setDrawerOpen(!drawerOpen)
    }

    const toggleCloseDrawer = () => {
        // condition to set previousFormState
        if (stockInputValue && selectedDate) {
            setPreviousFormState({
                stockInputValue,
                selectedDate,
                numberOfStocks,
                hasCustomizations,
                considerStockSplit,
                overrideAmount,
                overrideType,
            })
        };
        setDrawerOpen(!drawerOpen)
    }

    const toggleEditDrawer = () => {
        setEditMode(true)
        setDrawerOpen(!drawerOpen)
    }

    const toggleNewDrawer = () => {
        // Reset everything
        setEditMode(false)
        setStockInputValue(null);
        setNumberOfStocks();
        setSelectedDate(null);
        setHasCustomizations(false);
        setConsiderStockSplit(false);
        setOverrideAmount("");
        setOverrideType("");
        setDrawerOpen(!drawerOpen)
    }

    // useEffect(() => {
    //     console.log("Am I etting rerendering in Header?");
    // }, [store]);

    console.log("Home", home)

    return (
        <div>
            <Hidden mdUp>
                {/* Mobile */}
                <Grid
                    container
                    mt={2}
                    alignItems="center"
                    className={`${classes.mobileRoot}`}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                    >
                        <div className={classes.headerLogo}>
                            <picture className={classes.logoImage}>
                                {/* <source
                                srcSet="./assets/logo-long-white.png"
                                media="(prefers-color-scheme: dark)" /> */}
                                <img src="./assets/logo-long-white.png" alt="If I Invested . in logo" className={`${classes.logoImage} ${classes.logoImageWhite}`} />
                                <img src="./assets/logo-long-dark.png" alt="If I Invested . in logo" className={`${classes.logoImage} ${classes.logoImageDark}`} />
                            </picture>
                            <Typography className={classes.betaLabel}>
                                BETA
                            </Typography>
                        </div>
                        <Button
                        startIcon={<FaRegQuestionCircle />}
                        href="#howitworks"
                        className={classes.headerHowButton}
                        >
                            How it works
                        </Button>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        xs={12}
                        className={`${classes.secondaryButtonLine}`}
                    >
                        <Button
                            onClick={toggleNewDrawer}
                            startIcon={<HiPlus />}
                            variant="contained"
                            color="primary"
                            className={`${classes.headerMobileButton} ${graphData && stockInputValue && classes.twoHeaderMobileButtonRight}`}>
                            New Request
                        </Button>
                        {graphData && stockInputValue &&
                        <Button
                            onClick={toggleEditDrawer}
                            startIcon={<HiPencilAlt />}
                            variant="contained"
                            color="primary"
                            className={`${classes.headerMobileButton} ${classes.twoHeaderMobileButtonLeft}`}>
                            Edit
                        </Button>
                        }
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
        <Box className={`${classes.root}`} p={1}>
        {/* <Box p={1} flexGrow={1}> */}
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                xs={12}
                md={4}
                >
                <picture className={classes.logoImage}>
                    {/* <source
                        srcSet="./assets/logo-long-white.png"
                        media="(prefers-color-scheme: dark)" /> */}
                    <img src="./assets/logo-long-white.png" alt= "If I Invested . in logo" className={`${classes.logoImage} ${classes.logoImageWhite}`} />
                    <img src="./assets/logo-long-dark.png" alt= "If I Invested . in logo" className={`${classes.logoImage} ${classes.logoImageDark}`} />
                </picture>
                <Typography className={classes.betaLabel}>
                    BETA
                </Typography>
            </Grid>
        {/* </Box> */}
        {/* <Box
            p={1}
            justifyContent="center"
            style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}> */}
            <Grid
                container
                justify="flex-end"
                alignItems="center"
                xs={12}
                md={8}
            >
                <Button
                    startIcon={<FaRegQuestionCircle />}
                    href="#howitworks"
                    className={classes.headerHowButton}
                    >
                    How it works
                </Button>

                {/* <Hidden only={['xs']}> */}
                    <Button
                        onClick={toggleNewDrawer} 
                            startIcon={<HiPlus />}
                        variant="contained"
                        color="primary"
                        className={classes.headerButton}>
                            New Request
                    </Button>
                {/* </Hidden>
                <Hidden smUp> */}
                    {/* <IconButton aria-label="New Request" color="primary" className={classes.headerMobileButton} size="medium" onClick={toggleNewDrawer}>
                        <FaPlus fontSize="inherit" />
                    </IconButton> */}
                {/* </Hidden> */}
                {graphData && stockInputValue &&
                    <div>
                        {/* <Hidden only={['xs']}> */}
                            <Button
                                onClick={toggleEditDrawer} 
                                startIcon={<HiPencilAlt />}
                                variant="contained"
                                color="primary"
                                className={classes.headerButton}>
                                Edit
                            </Button>
                        {/* </Hidden> */}
                        {/* <Hidden smUp>
                            <IconButton aria-label="Edit" color="primary" className={classes.headerMobileButton} size="medium" onClick={toggleEditDrawer}>
                                <HiPencilAlt fontSize="inherit" />
                            </IconButton>
                        </Hidden> */}
                    </div>
                }
            </Grid>
        {/* </Box> */}
        {/* <Box
            p={1}
            justifyContent="center"
            style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}>
            <Button
                startIcon={<FaEdit />}
                variant="contained" 
                color="primary" 
                className={classes.headerButton}>
                    Edit
            </Button>
        </Box> */}
        </Box>
        </Hidden>
        <Drawer
            // width={600}
            open={drawerOpen}
            anchor='right'
            className={classes.drawerParent}
            >
                <Form width={"600px"} handleSuccessSubmit={toggleCloseDrawer} store={store} handleCancel={toggleCancelDrawer} />
        </Drawer>
        </div>
    )
}

export default Header;